import { useState, useEffect } from 'react';
import { ref, get, set } from 'firebase/database';
import { getDatabase } from 'firebase/database';import './forms.css';
import { auth } from './firebase';
import { useNavigate, Link } from 'react-router-dom';
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from 'firebase/auth';
import { useAuthValue } from './AuthContext';
import allowedEmails from './allowedEmails';
import bannerImage from './images/Banner.jpg';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

function Register() {
  const [users, setUsers] = useState([]);
  const database = getDatabase();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { setTimeActive } = useAuthValue();

  const getUsers = () => {
    const dbRef = ref(database, 'allowed-users/');
    get(dbRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          const newArray = Object.values(data).sort(); // Sort the array alphabetically
          setUsers(newArray);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const validatePassword = () => {
    let isValid = true;
    if (password !== '' && confirmPassword !== '') {
      if (password !== confirmPassword) {
        isValid = false;
        setError('Passwords do not match');
      }
    }
    return isValid;
  };

  const register = (e) => {
    e.preventDefault();
    setError('');

    if (!users.includes(email)) {
      setError('Email address is not allowed');
      return;
    }

    if (validatePassword()) {
      setIsLoading(true);

      createUserWithEmailAndPassword(auth, email, password)
        .then(() => {
          sendEmailVerification(auth.currentUser)
            .then(() => {
              setTimeActive(true);
              navigate('/verify-email');
            })
            .catch((err) => alert(err.message?.replace(/firebase:/gi, '').trim()));
        })
        .catch((err) => {
          const errorMessage = err.message?.replace(/firebase:/gi, '');
          setError(errorMessage.trim());
          setIsLoading(false);
        })
        .finally(() => {
          setEmail('');
          setPassword('');
          setConfirmPassword('');
        });
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <div>
    <div>
        <img src={bannerImage} alt="Banner" style={{
          width: '100%',
          maxWidth: '100%',
          height: 'auto',
          display: 'block',
          paddingBottom: '40px',
        }} />
      </div>
    <div className="center">
      <div className="auth">
        <h1 className="auth-title">Sign Up</h1>
        {error && <div className="auth__error">{error}</div>}
        {isLoading ? (
          <div className="auth__loading">Loading...</div>
        ) : (
            <form name="registration_form">
              <div className="form-group">
                <input
                  type="email"
                  value={email}
                  placeholder="Enter your email"
                  required
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <div className="form-group">
                <div className="password-input">
                  <input
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    required
                    placeholder="Enter your password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <button
                    type="button"
                    className="password-toggle-btn"
                    onClick={() => setShowPassword(!showPassword)}
                    aria-label={showPassword ? 'Hide password' : 'Show password'}
                  >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </button>
                </div>
              </div>

              <div className="form-group">
                <div className="password-input">
                  <input
                    type={showPassword2 ? 'text' : 'password'}
                    value={confirmPassword}
                    required
                    placeholder="Enter your password"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                  <button
                    type="button"
                    className="password-toggle-btn"
                    onClick={() => setShowPassword2(!showPassword2)}
                    aria-label={showPassword2 ? 'Hide password' : 'Show password'}
                  >
                    {showPassword2 ? <FaEyeSlash /> : <FaEye />}
                  </button>
                </div>
              </div>


              <button className="btn-register" onClick={register}>
                Register
            </button>
            </form>
          )}
        <p className="auth__text">
          Already have an account? <Link to="/login">Login</Link>
        </p>
      </div>
    </div>
    </div>
  );
}

export default Register;
