import './verifyEmail.css'
import { useAuthValue } from './AuthContext'
import { useState, useEffect } from 'react'
import { auth } from './firebase'
import { sendEmailVerification } from 'firebase/auth'
import { useNavigate } from 'react-router-dom'
import bannerImage from './images/Banner.jpg';

function VerifyEmail() {

  const { currentUser } = useAuthValue()
  const [time, setTime] = useState(60)
  const { timeActive, setTimeActive } = useAuthValue()
  const navigate = useNavigate()

  useEffect(() => {
    const interval = setInterval(() => {
      currentUser?.reload()
        .then(() => {
          if (currentUser?.emailVerified) {
            clearInterval(interval)
            navigate('/')
          }
        })
        .catch((err) => {
          alert(err.message)
        })
    }, 1000)
  }, [navigate, currentUser])

  useEffect(() => {
    let interval = null
    if (timeActive && time !== 0) {
      interval = setInterval(() => {
        setTime((time) => time - 1)
      }, 1000)
    } else if (time === 0) {
      setTimeActive(false)
      setTime(60)
      clearInterval(interval)
    }
    return () => clearInterval(interval);
  }, [timeActive, time, setTimeActive])

  const resendEmailVerification = () => {
    console.log("resending")
    sendEmailVerification(auth.currentUser)
      .then(() => {
        setTimeActive(true)
      }).catch((err) => {
        alert(err.message)
      })
  }

  return (
    <div>
      <div>
        <img src={bannerImage} alt="Banner" style={{
          width: '100%',
          maxWidth: '100%',
          height: 'auto',
          display: 'block',
        }} />
      </div>
      <div className='center'>

        <div className='verifyEmail'>
          <h1>Verify your Email Address</h1>
          <p>
            <strong>A Verification email has been sent to:</strong><br />
            <span>{currentUser?.email}</span>
          </p>
          <span>Follow the instruction in the email to verify your account. If the message doesn't appear in your in-box, please check your junk folder.</span>
          <button
            onClick={resendEmailVerification}
            disabled={timeActive}
            className="btn-resend-email"
          >Resend Email {timeActive && time}</button>
        </div>
      </div>
    </div>

  )
}

export default VerifyEmail
