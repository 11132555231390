import { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import './forgotpassword.css';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from './firebase';
import bannerImage from './images/Banner.jpg';

function ForgotPassword() {
  const [resetEmail, setResetEmail] = useState('');
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handlePasswordReset = (e) => {
    e.preventDefault();

    if (resetEmail) {
      sendPasswordResetEmail(auth, resetEmail)
        .then(() => {
          setIsEmailSent(true);
        })
        .catch((error) => {
          setError(error.message);
        })
        .finally(() => {
          setResetEmail('');
        });
    }
  };

  const goBack = () => {
    navigate('/login');
  };

  return (
    <div>
{/* <div className="backButtonContainer">
      <button className="back-button" onClick={goBack}>
        Back
      </button>
    </div> */}
    <div className="bannerContainer">
      <img
        src={bannerImage}
        alt="Banner"
        style={{
          width: '100%',
          maxWidth: '100%',
          height: 'auto',
          display: 'block',
        }}
      />
    </div>
      <div className="center">

        <div className="auth">
          <div className="auth__content">
            <h2 className="auth-title">Forgot Password</h2>

            {isEmailSent ? (
              <>
                <p className="auth__success">Check your email to reset your password</p>
                <button className="btn-register" onClick={() => navigate('/login')}>
                  Go to Login
            </button>
              </>
            ) : (
                <form onSubmit={handlePasswordReset} name="forgot_password_form">
                  {error && <div className="auth__error">{error}</div>}
                  <div className="form-group">
                    <input
                      type="email"
                      value={resetEmail}
                      required
                      placeholder="Enter your email"
                      onChange={(e) => setResetEmail(e.target.value)}
                    />
                  </div>
                  <button type="submit" className="btn-login">
                    Submit
              </button>
                </form>
              )}
          </div>
          <p className="auth__text">
          Remembered your password? <Link to="/login">Login</Link>
        </p>
        <p className="auth__text">
            Don't have an account? <Link to="/register">Create one here</Link>
          </p>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
