import './App.css';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import Profile from './Profile'
import Register from './Register'
import VerifyEmail from './VerifyEmail';
import Login from './Login'
import Admin from './Admin'
import ForgotPassword from './ForgotPassword'
import AdminProfile from './AdminProfile'
import AdminUsers from './AdminUsers'
import {useState, useEffect} from 'react'
import {AuthProvider} from './AuthContext'
import {auth} from './firebase'
import {onAuthStateChanged} from 'firebase/auth'
import PrivateRoute from './PrivateRoute'
import {Navigate} from 'react-router-dom'

function App() {

  const [currentUser, setCurrentUser] = useState(null)
  const [timeActive, setTimeActive] = useState(false)

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setCurrentUser(user)
    })
  }, [])

  return (
    <Router>
      <AuthProvider value={{currentUser, timeActive, setTimeActive}}>
        <Routes>
          <Route exact path='/' element={
              <PrivateRoute>
                <Profile />
              </PrivateRoute>
          }/>
          <Route
  path="/login"
  element={
    !currentUser || !currentUser.emailVerified ? (
      <Login />
    ) : (
      <Navigate to="/" replace />
    )
  }
/>
          <Route path="/register" element={
            !currentUser?.emailVerified 
            ? <Register/>
            : <Navigate to='/' replace/>
          } />
          <Route path='/verify-email' element={<VerifyEmail/>} /> 
          <Route path='/forgot-password' element={<ForgotPassword/>} /> 
          <Route path='/admin' element={<Admin/>} /> 
          <Route path='/admin-users' element={<AdminUsers/>} /> 

          <Route path="/admin-profile/:userId" element={<AdminProfile/>} /> 
          {/* <Route path='/' element={<HomePage/>} />  */}
        </Routes>  
      </AuthProvider>
  </Router>
  );
}

export default App;
