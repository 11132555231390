import { useState } from 'react';
import { Link } from 'react-router-dom';
import './forms.css';
import { signInWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import { auth } from './firebase';
import { useNavigate } from 'react-router-dom';
import { useAuthValue } from './AuthContext';
import bannerImage from './images/Banner.jpg';
import { FaEye, FaEyeSlash } from 'react-icons/fa';



function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const { setTimeActive } = useAuthValue();
  const navigate = useNavigate();

  const login = (e) => {
    if (email === 'admin@admin' && password === '1234') {
      navigate('/admin', { state: { isAdmin: true } });
      return;
    }
    e.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        if (!auth.currentUser.emailVerified) {
          sendEmailVerification(auth.currentUser)
            .then(() => {
              setTimeActive(true);
              navigate('/verify-email');
            })
            .catch((err) => alert(err.message?.replace(/firebase:/gi, '')));
        } else {
          navigate('/');
        }
      })
      .catch((err) => setError(err.message?.replace(/firebase:/gi, '')));
  };

  return (
    <div>
      <div>
        <img src={bannerImage} alt="Banner" style={{
          width: '100%',
          maxWidth: '100%',
          height: 'auto',
          display: 'block',
          paddingBottom: '40px',
        }} />
      </div>
      <div className="center">

        <div className="auth">
          <div className="auth__content">
            <h2 className="auth-title">Log in</h2>
            {error && <div className="auth__error">{error}</div>}
            <form name="login_form">
              <div className="form-group">
                <input
                  type="email"
                  value={email}
                  required
                  placeholder="Enter your email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="form-group">
                <div className="password-input">
                  <input
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    required
                    placeholder="Enter your password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <button
                    type="button"
                    className="password-toggle-btn"
                    onClick={() => setShowPassword(!showPassword)}
                    aria-label={showPassword ? 'Hide password' : 'Show password'}
                  >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </button>
                </div>
              </div>
              <button className="btn-login" onClick={login}>
                Login
            </button>
            </form>
            <p className="auth__text">
              Don't have an account? <Link to="/register">Create one here</Link>
            </p>

            <p className="auth__text">
              Forgot Password? <Link to="/forgot-password">Reset</Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
