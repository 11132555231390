import './profile.css';
import { useAuthValue } from './AuthContext';
import { signOut } from 'firebase/auth';
import { auth, handleDownload } from './firebase';
import { useState, useEffect, useRef } from 'react';
import { ref, set, child, get } from 'firebase/database';
import { getDatabase } from 'firebase/database';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
// import { getStorage, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { uploadFile } from './firebase.js';
import { useParams } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import Select from 'react-select';

function AdminProfile(props) {
  const database = getDatabase();
  const navigate = useNavigate();
  const { state } = useLocation();


  const [firstName, setfirstName] = useState('');
  const [lastName, setlastName] = useState('');
  const [address1, setaddress1] = useState('');
  const [address2, setaddress2] = useState('');
  const [city, setCity] = useState('');
  const [state1, setState] = useState('');
  const [zip, setZip] = useState('');
  const [email1, setEmail1] = useState('');
  const [email2, setEmail2] = useState('');

  const [currentEmployer, setCurrentEmployer] = useState('');
  const [previousEmployer, setPreviousEmployer] = useState('');
  const [previousEmployer2, setPreviousEmployer2] = useState('');
  const [previousEmployer3, setPreviousEmployer3] = useState('');

  const [expPartnership, setExpPartnership] = useState('');
  const [expHighWealth, setExpHighWealth] = useState('');
  const [expMultiNational, setExpMultiNational] = useState('');
  const [expDomestic, setExpDomestic] = useState('');

  const [expOther1, setExpOther1] = useState('');
  const [expOther2, setExpOther2] = useState('');
  const [expOther3, setExpOther3] = useState('');
  const [expOther4, setExpOther4] = useState('');

  const [yearsExperience1, setYearsExperience1] = useState("");
  const [yearsExperience2, setYearsExperience2] = useState("");

  const [primaryIndustry, setPrimaryIndustry] = useState("");
  const [secondaryIndustry, setSecondaryIndustry] = useState("");
  const [otherIndustry1, setOtherIndustry1] = useState("");
  const [otherIndustry2, setOtherIndustry2] = useState("");

  const [availablity, setAvailablity] = useState("");
  const [availablityToTravel, setAvailablityToTravel] = useState("");

  const [availablityStartDate, setAvailablityStartDate] = useState(null);
  const [availablityEndDate, setAvailablityEndDate] = useState(null);

  const [availablityStartDateString, setAvailablityStartDateString] = useState(null);
  const [availablityEndDateString, setAvailablityEndDateString] = useState(null);

  const [credentials, setCredentials] = useState("");
  const [resumeName, setResumeName] = useState("")

  const [laborCategory, setLaborCategory] = useState("");
  const [payRate, setPayRate] = useState("")
  const [billingRate, setBillingRate] = useState("")

  const [primaryRole, setPrimaryRole] = useState("");
  const [secondaryRole, setSecondaryRole] = useState("");

  const [otherEmployer, setOtherEmployer] = useState('');

  const [agency, setAgency] = useState('');
  const [otherAgency, setOtherAgency] = useState('');
  const [yearsFederal, setYearsFederal] = useState("");

  const [primaryIrs, setPrimaryIrs] = useState('');
  const [otherIrs1, setOtherIrs1] = useState('');
  const [secondaryIrs, setSecondaryIrs] = useState('');
  const [otherIrs2, setOtherIrs2] = useState('');

  const [institution, setInstitution] = useState('');
  const [courses, setCourses] = useState('');

  const [phone1, setPhone1] = useState('');
  const [phone2, setPhone2] = useState('');

  const { userId } = useParams();

  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleSelectChange = (credentials) => {
    setCredentials(credentials);
  };

  const options = [
    { value: 'CPA', label: 'CPA' },
    { value: 'JD', label: 'JD' },
    { value: 'Masters', label: 'Masters' },
    { value: 'PhD', label: 'PhD' },
    { value: 'Other', label: 'Other' }
  ];


  const [isFormValid, setIsFormValid] = useState(false);


  const industries = [
    'Automotive',
    'Banking & Capital Markets',
    'Construction',
    'Consumer Products',
    'Distribution',
    'Engineering',
    'Exempt Organizations',
    'Financial Services',
    'Hospitality',
    'Life Sciences',
    'Manufacturing',
    'Oil & Gas',
    'Professional Services',
    'Real Estate',
    'Retail',
    'Sports and Entertainment',
    'Technology',
    'Telecommunications',
    'Wealth & Asset Management'
  ];


  const areas = [
    'Auditing (financial statements)',
    'Corporate structuring',
    'Cross border restructuring',
    'Data analytics',
    'Domectis passthroughs',
    'Foreign Account Tax Compliant Act (FATCA)',
    'Foreign Investment in Real Property Tax Act (FIRPTA)',
    'Foreign Tax Credit',
    'Hedge funds',
    'High wealth families',
    'International Financial Reporting Standards (IFRS)',
    'High wealth individuals',
    'Large Multi-Nationals',
    'M&A due diligence',
    'Passive foreign investment companies (PFIC)',
    'Post-transaction integrations',
    'Pre-Filing Program',
    'Private Equity',
    'Repatriation Planning',
    'Sovereign wealth funds',
    'Statistical modeling',
    'Statistical sampling',
    'Tax controversy and dispute resolution',
    'Tax effective supply chain planning',
    'Territories (U.S.) and issues related to',
    'Transaction tax Carve Outs',
    'Transfer pricing'
  ];

  const firms = [
    'BDO',
    'Deloitte',
    'Ernst & Young',
    'Grant Thornton',
    'KPMG',
    'PwC',
    'RSM',
    'Other'
  ];

  const laborCategories = [
    "Tier 1",
    "Tier 2",
    "Tier 3",
    "Tier 4",
    "Tier 5",
    "Tier 6",
    "Tier 7",
    "Tier 8",
    "Program Manager",
    "Deputy Program Manager",
    "PMO Staff"
  ];

  const roles = [
    'Audit',
    'Planning',
    'Controversy',
    'Other'
  ]


  const agencies = [
    'Internal Revenue Service',
    'Department of the Treasury (non-IRS)',
    'Department of Justice',
    'Any of the relevant Tax Committees in Congress',
    'Other'
  ];

  const irsOrgs = [
    'Large Business & International (LB&I)',
    'Small Business/Self Employed (SBSE)',
    'Tax Exempt/Government Entities (TEGE)',
    'Wage & Investment (W&I)',
    'Office of Appeals',
    'Office of Chief Counsel',
    'Other'
  ];

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    console.log("setting resume name 1")
    setResumeName(file.name);
    // You can perform additional logic or actions with the selected file here
  };

  //   const handleUpload = () => {
  //     // Get the selected file from the file input

  //     const file = document.getElementById('fileInput').files[0];
  //     const fileName = currentUser?.uid; // Replace with your desired file name
  //     if (file) {
  //       const resumeName = file.name; // Retrieve the name of the uploaded file
  //       console.log("setting resume name")
  //       setResumeName(resumeName);
  //       uploadFile(file, fileName);
  //     }
  //   };



  // const [file, setFile] = useState("");

  // progress
  // const [percent, setPercent] = useState(0);

  // Handle file upload event and update state
  // function handleChange(event) {
  //   setFile(event.target.files[0]);
  // }

  // const handleUpload = () => {
  //   if (!file) {
  //     alert("Please upload an image first!");
  //   }

  //   const storageRef = ref(storage, `/files/${file.name}`);

  //   // progress can be paused and resumed. It also exposes progress updates.
  //   // Receives the storage reference and the file to upload.
  //   const uploadTask = uploadBytesResumable(storageRef, file);

  //   uploadTask.on(
  //     "state_changed",
  //     (snapshot) => {
  //       const percent = Math.round(
  //         (snapshot.bytesTransferred / snapshot.totalBytes) * 100
  //       );

  //       // update progress
  //       setPercent(percent);
  //     },
  //     (err) => console.log(err),
  //     () => {
  //       // download url
  //       getDownloadURL(uploadTask.snapshot.ref).then((url) => {
  //         console.log(url);
  //       });
  //     }
  //   );
  // };


  useEffect(() => {

    const dbRef = ref(database);
    get(child(dbRef, `users/` + userId))
      .then((snapshot) => {
        if (snapshot.exists()) {
          const userData = snapshot.val();
          if (userData.hasOwnProperty('firstName')) {
            setfirstName(userData.firstName);
          }
          if (userData.hasOwnProperty('lastName')) {
            setlastName(userData.lastName);
          }
          if (userData.hasOwnProperty('address1')) {
            setaddress1(userData.address1);
          }
          if (userData.hasOwnProperty('address2')) {
            setaddress2(userData.address2);
          }
          if (userData.hasOwnProperty('city')) {
            setCity(userData.city);
          }
          if (userData.hasOwnProperty('state')) {
            setState(userData.state);
          }
          if (userData.hasOwnProperty('zip')) {
            setZip(userData.zip);
          }
          if (userData.hasOwnProperty('email1')) {
            setEmail1(userData.email1);
          }
          if (userData.hasOwnProperty('email2')) {
            setEmail2(userData.email2);
          }
          if (userData.hasOwnProperty('currentEmployer')) {
            setCurrentEmployer(userData.currentEmployer);
          }
          if (userData.hasOwnProperty('previousEmployer')) {
            setPreviousEmployer(userData.previousEmployer);
          }
          if (userData.hasOwnProperty('previousEmployer2')) {
            setPreviousEmployer2(userData.previousEmployer2);
          }
          if (userData.hasOwnProperty('previousEmployer3')) {
            setPreviousEmployer3(userData.previousEmployer3);
          }

          if (userData.hasOwnProperty('expPartnership')) {
            setExpPartnership(userData.expPartnership);
          }
          if (userData.hasOwnProperty('expHighWealth')) {
            setExpHighWealth(userData.expHighWealth);
          }
          if (userData.hasOwnProperty('expMultiNational')) {
            setExpMultiNational(userData.expMultiNational);
          }
          if (userData.hasOwnProperty('expDomestic')) {
            setExpDomestic(userData.expDomestic);
          }

          if (userData.hasOwnProperty('expOther1')) {
            setExpOther1(userData.expOther1);
          }
          if (userData.hasOwnProperty('expOther2')) {
            setExpOther2(userData.expOther2);
          }
          if (userData.hasOwnProperty('expOther3')) {
            setExpOther3(userData.expOther3);
          }
          if (userData.hasOwnProperty('expOther4')) {
            setExpOther4(userData.expOther4);
          }

          if (userData.hasOwnProperty('yearsExperience1')) {
            setYearsExperience1(userData.yearsExperience1);
          }
          if (userData.hasOwnProperty('yearsExperience2')) {
            setYearsExperience2(userData.yearsExperience2);
          }
          if (userData.hasOwnProperty('primaryIndustry')) {
            setPrimaryIndustry(userData.primaryIndustry);
          }
          if (userData.hasOwnProperty('secondaryIndustry')) {
            setSecondaryIndustry(userData.secondaryIndustry);
          }
          if (userData.hasOwnProperty('otherIndustry1')) {
            setOtherIndustry1(userData.otherIndustry1);
          }
          if (userData.hasOwnProperty('otherIndustry2')) {
            setOtherIndustry2(userData.otherIndustry2);
          }

          if (userData.hasOwnProperty('primaryRole')) {
            setPrimaryRole(userData.primaryRole);
          }
          if (userData.hasOwnProperty('secondaryRole')) {
            setSecondaryRole(userData.secondaryRole);
          }

          if (userData.hasOwnProperty('availablity')) {
            setAvailablity(userData.availablity);
          }
          if (userData.hasOwnProperty('availablityToTravel')) {
            setAvailablityToTravel(userData.availablityToTravel);
          }
          if (userData.hasOwnProperty('credentials')) {
            setCredentials(userData.credentials);
          }

          if (userData.hasOwnProperty('availablityStartDate')) {
            const startDateString = userData.availablityStartDate;
            const startDate = new Date(startDateString);
            setAvailablityStartDateString(startDateString)
            setAvailablityStartDate(startDate);
          }
          if (userData.hasOwnProperty('availablityEndDate')) {
            const endDateString = userData.availablityEndDate;
            const endDate = new Date(endDateString);
            setAvailablityEndDateString(endDateString)
            setAvailablityEndDate(endDate);
          }

          if (userData.hasOwnProperty('resumeName')) {
            setResumeName(userData.resumeName);
          }

          if (userData.hasOwnProperty('laborCategory')) {
            setLaborCategory(userData.laborCategory);
          }
          if (userData.hasOwnProperty('payRate')) {
            setPayRate(userData.payRate);
          }
          if (userData.hasOwnProperty('billingRate')) {
            setBillingRate(userData.billingRate);
          }

          if (userData.hasOwnProperty('otherEmployer')) {
            setOtherEmployer(userData.otherEmployer);
          }
          if (userData.hasOwnProperty('agency')) {
            setAgency(userData.agency);
          }
          if (userData.hasOwnProperty('otherAgency')) {
            setOtherAgency(userData.otherAgency);
          }
          if (userData.hasOwnProperty('yearsFederal')) {
            setYearsFederal(userData.yearsFederal);
          }


          if (userData.hasOwnProperty('primaryIrs')) {
            setPrimaryIrs(userData.primaryIrs);
          }
          if (userData.hasOwnProperty('secondaryIrs')) {
            setSecondaryIrs(userData.secondaryIrs);
          }
          if (userData.hasOwnProperty('otherIrs1')) {
            setOtherIrs1(userData.otherIrs1);
          }
          if (userData.hasOwnProperty('otherIrs2')) {
            setOtherIrs2(userData.otherIrs2);
          }

          if (userData.hasOwnProperty('institution')) {
            setInstitution(userData.institution);
          }
          if (userData.hasOwnProperty('courses')) {
            setCourses(userData.courses);
          }

          if (userData.hasOwnProperty('phone1')) {
            setPhone1(userData.phone1);
          }
          if (userData.hasOwnProperty('phone2')) {
            setPhone2(userData.phone2);
          }
        } else {
          console.log('No data available');
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [userId, database]);

  const validateForm = () => {
    const yearsExperience1Number = parseInt(yearsExperience1);
    const yearsExperience2Number = parseInt(yearsExperience2);

    const isValid =
      firstName &&
      lastName &&
      address1 &&
      city &&
      state1 &&
      zip &&
      currentEmployer &&
      previousEmployer &&
      yearsExperience1 &&
      yearsExperience2 &&
      !isNaN(yearsExperience1Number) &&
      !isNaN(yearsExperience2Number) &&
      expPartnership &&
      expHighWealth &&
      expMultiNational &&
      expDomestic &&
      primaryIndustry &&
      availablity &&
      availablityToTravel &&
      expOther1 &&
      resumeName;

    // if (!isValid) {
    //   if (!firstName) console.log("First Name is not filled out");
    //   if (!lastName) console.log("Last Name is not filled out");
    //   if (!address1) console.log("Address 1 is not filled out");
    //   if (!city) console.log("City is not filled out");
    //   if (!state) console.log("State is not filled out");
    //   if (!zip) console.log("Zip is not filled out");
    //   if (!currentEmployer) console.log("Current Employer is not filled out");
    //   if (!previousEmployer) console.log("Previous Employer is not filled out");
    //   if (!yearsExperience1) console.log("Years of Experience 1 is not filled out");
    //   if (!yearsExperience2) console.log("Years of Experience 2 is not filled out");
    //   if (isNaN(yearsExperience1Number)) console.log("Years of Experience 1 is not a number");
    //   if (isNaN(yearsExperience2Number)) console.log("Years of Experience 2 is not a number");
    //   if (!expPartnership) console.log("Partnership experience is not filled out");
    //   if (!expHighWealth) console.log("High wealth experience is not filled out");
    //   if (!expMultiNational) console.log("Multi-national experience is not filled out");
    //   if (!expDomestic) console.log("Domestic experience is not filled out");
    //   if (!primaryIndustry) console.log("Primary industry is not filled out");
    //   if (!availablity) console.log("Availability is not filled out");
    //   if (!availablityToTravel) console.log("Availability to Travel is not filled out");
    //   if (!expOther1) console.log("expother1 is not filled out");

    // }

    setIsFormValid(isValid);
  };



  const writeUserData = () => {
    let formattedStartDate = null;
    if (availablityStartDate) {
      formattedStartDate = format(availablityStartDate, 'yyyy-MM-dd');
    }

    let formattedEndDate = null;
    if (availablityEndDate) {
      formattedEndDate = format(availablityEndDate, 'yyyy-MM-dd');
    }
    set(ref(database, 'users/' + userId), {
      firstName: firstName,
      lastName: lastName,
      address1: address1,
      address2: address2,
      city: city,
      state: state1,
      zip: zip,
      email1: email1,
      email2: email2,
      currentEmployer: currentEmployer,
      previousEmployer: previousEmployer,
      previousEmployer2: previousEmployer2,
      previousEmployer3: previousEmployer3,
      expPartnership: expPartnership,
      expHighWealth: expHighWealth,
      expMultiNational: expMultiNational,
      expDomestic: expDomestic,
      expOther1: expOther1,
      expOther2: expOther2,
      expOther3: expOther3,
      expOther4: expOther4,
      yearsExperience1: yearsExperience1,
      yearsExperience2: yearsExperience2,
      primaryIndustry: primaryIndustry,
      secondaryIndustry: secondaryIndustry,
      primaryRole: primaryRole,
      secondaryRole: secondaryRole,
      otherIndustry1: otherIndustry1,
      otherIndustry2: otherIndustry2,
      availablity: availablity,
      availablityToTravel: availablityToTravel,
      availablityStartDate: formattedStartDate,
      availablityEndDate: formattedEndDate,
      credentials: credentials,
      resumeName: resumeName,
      laborCategory: laborCategory,
      payRate: payRate,
      billingRate: billingRate,
      otherEmployer: otherEmployer,
      agency: agency,
      otherAgency: otherAgency,
      yearsFederal: yearsFederal,
      primaryIrs: primaryIrs,
      secondaryIrs: secondaryIrs,
      otherIrs1: otherIrs1,
      otherIrs2: otherIrs2,
      institution: institution,
      courses: courses,
      phone1: phone1,
      phone2: phone2
    })
      .then(() => {
        alert('Data submitted successfully!');
      })
      .catch((error) => {
        console.error('Error saving data:', error);
        alert('Error saving data. Please try again.');
      });
  };

  //   if (!adminState || !adminState.isAdmin) {
  //     return <h1>Unauthorized</h1>;
  //   }

  if (!state.isAdmin) {
    return <h1>Unauthorized</h1>
  }

  return (


    <div className='center1'>
      <div className='profile'>
        <div class="button-container">
        </div>
        <h1>Profile</h1>

        {/* <p>
          <strong>Email: </strong>
          {currentUser?.email}
        </p>
        <p>
          <strong>Email verified: </strong>
          {`${currentUser?.emailVerified}`}
        </p> */}
        <div className='form-row'>
          <div>
            <label className='input-label'>
              First Name
  <span className='required-indicator'>*</span>
            </label>
            <input
              type='text'
              placeholder=''
              value={firstName}
              onChange={(e) => setfirstName(e.target.value)}
            />
          </div>
          <div>
            <label className='input-label'>Last Name<span className='required-indicator'>*</span></label>
            <input
              type='text'
              placeholder=''
              value={lastName}
              onChange={(e) => setlastName(e.target.value)}
            />
          </div>
        </div>
        <div className='form-row'>
          <div>
            <label className='input-label'>Address 1<span className='required-indicator'>*</span></label>
            <input
              type='text'
              id='address1'
              placeholder=''
              value={address1}
              onChange={(e) => setaddress1(e.target.value)}
            />
          </div>
          <div>
            <label className='input-label'>Address 2</label>
            <input
              type='text'
              id='address2'
              placeholder=''
              value={address2}
              onChange={(e) => setaddress2(e.target.value)}
            />
          </div>
        </div>

        <div className='form-row'>
          <div>
            <label className='input-label'>City<span className='required-indicator'>*</span></label>
            <input
              type='text'
              placeholder=''
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
          </div>
          <div>
            <label className='input-label'>State<span className='required-indicator'>*</span></label>
            <input
              type='text'
              placeholder=''
              value={state1}
              onChange={(e) => setState(e.target.value)}
            />
          </div>
          <div>
            <label className='input-label'>Zip<span className='required-indicator'>*</span></label>
            <input
              type='text'
              placeholder=''
              value={zip}
              onChange={(e) => setZip(e.target.value)}
            />
          </div>
        </div>
        <div className='form-row'>
          <div>
            <label className='input-label'>Primary Email<span className='required-indicator'>*</span></label>
            <input
              type='text'
              placeholder=''
              value={email1}
              onChange={(e) => setEmail1(e.target.value)}
            />
          </div>
          <div>
            <label className='input-label'>Secondary Email</label>
            <input
              type='text'
              placeholder=''
              value={email2}
              onChange={(e) => setEmail2(e.target.value)}
            />
          </div>
        </div>
        <div className='form-row'>
          <div>
            <label className='input-label'>Primary Phone #</label>
            <input
              type='text'
              placeholder=''
              value={phone1}
              onChange={(e) => setPhone1(e.target.value)}
            />
          </div>
          <div>
            <label className='input-label'>Secondary Phone #</label>
            <input
              type='text'
              placeholder=''
              value={phone2}
              onChange={(e) => setPhone2(e.target.value)}
            />
          </div>
        </div>
        <div className='form-row space-between-rows'>
          <div>
            <label className='input-label'>Current Employer (if none, enter “none”; if self-employed, enter “self”)
<span className='required-indicator'>*</span></label>
            <input
              type='text'
              placeholder=''
              value={currentEmployer}
              onChange={(e) => setCurrentEmployer(e.target.value)}
            />
          </div>
        </div>
        <div className='form-row'>
          <div>
            <label className='input-label'>
              Previous Employer (the firm from which you retired)
    <span className='required-indicator'>*</span>
            </label>
            <select
              value={previousEmployer}
              onChange={(e) => {
                if (e.target.value === 'Other') {
                  setPreviousEmployer(e.target.value);
                } else {
                  setPreviousEmployer(e.target.value);
                  setOtherEmployer('');
                }
              }}
            >
              <option value=''>{'\u00A0'}</option>
              {firms.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            {previousEmployer === 'Other' && (
              <div>
                <label className='input-label'>Other Employer</label>
                <input
                  type='text'
                  value={otherEmployer}
                  onChange={(e) => setOtherEmployer(e.target.value)}
                />
              </div>
            )}
          </div>
        </div>
        <div className='form-row'>
          <div>
            <label className='input-label'>Other Public Accounting Firm Employer</label>
            <select
              value={previousEmployer2}
              onChange={(e) => setPreviousEmployer2(e.target.value)}
            >
              <option value=''>{'\u00A0'}</option>
              {firms.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label className='input-label'>Other Public Accounting Firm Employer</label>
            <select
              value={previousEmployer3}
              onChange={(e) => setPreviousEmployer3(e.target.value)}
            >
              <option value=''>{'\u00A0'}</option>
              {firms.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className='form-row'>
          <div>
            <label className='input-label'># Years of Tax-Related Experience<span className='required-indicator'>*</span></label>
            <input
              type='text'
              placeholder=''
              value={yearsExperience1}
              onChange={(e) => setYearsExperience1(e.target.value)}
            />
          </div>
          <div>
            <label className='input-label'>“# Years as Public Accounting Partner, or equivalent<span className='required-indicator'>*</span></label>
            <input
              type='text'
              placeholder=''
              value={yearsExperience2}
              onChange={(e) => setYearsExperience2(e.target.value)}
            />
          </div>
        </div>

        <div className='form-row'>
          <div className='centerlabel-container'> {/* Add a container div */}
            <label>Have you previously worked for a Federal agency?</label>
          </div>
        </div>

        <div className='form-row space-between-rows'>
          <div>
            <label className='input-label'>If yes, please select the primary agency for which you worked</label>
            <select
              value={agency}
              onChange={(e) => {
                if (e.target.value === 'Other') {
                  setAgency(e.target.value);
                } else {
                  setAgency(e.target.value);
                  setOtherAgency('');
                }
              }}
            >
              <option value=''>{'\u00A0'}</option>
              {agencies.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            {agency === 'Other' && (
              <div>
                <label className='input-label'>Other Agency</label>
                <input
                  type='text'
                  value={otherAgency}
                  onChange={(e) => setOtherAgency(e.target.value)}
                />
              </div>
            )}
          </div>
          <div>
            <label className='input-label'>If yes, how many total years did you work for the Federal government?</label>
            <input
              type='text'
              placeholder=''
              value={yearsFederal}
              onChange={(e) => setYearsFederal(e.target.value)}
            />
          </div>
        </div>

        <div className='form-row'>
          <div>
            <label className='input-label'>Experience with Partnerships<span className='required-indicator'>*</span></label>
            <select
              value={expPartnership}
              onChange={(e) => setExpPartnership(e.target.value)}
            >
              <option value=''>{'\u00A0'}</option>
              <option value='4'>Extremely Experienced</option>
              <option value='3'>Very Experienced</option>
              <option value='2'>Moderately Experienced</option>
              <option value='1'>Less than Moderately Experienced</option>
            </select>
          </div>
          <div>
            <label className='input-label'>Experience with High Wealth Individuals<span className='required-indicator'>*</span></label>
            <select
              value={expHighWealth}
              onChange={(e) => setExpHighWealth(e.target.value)}
            >
              <option value=''>{'\u00A0'}</option>
              <option value='4'>Extremely Experienced</option>
              <option value='3'>Very Experienced</option>
              <option value='2'>Moderately Experienced</option>
              <option value='1'>Less than Moderately Experienced</option>
            </select>
          </div>
        </div>
        <div className='form-row'>
          <div>
            <label className='input-label'>Experience with Multi-National Corporations<span className='required-indicator'>*</span></label>
            <select value={expMultiNational} onChange={(e) => setExpMultiNational(e.target.value)}>
              <option value=''>{'\u00A0'}</option>
              <option value='4'>Extremely Experienced</option>
              <option value='3'>Very Experienced</option>
              <option value='2'>Moderately Experienced</option>
              <option value='1'>Less than Moderately Experienced</option>
            </select>
          </div>
          <div>
            <label className='input-label'>Experience with Domestic Corporations<span className='required-indicator'>*</span></label>
            <select
              value={expDomestic}
              onChange={(e) => setExpDomestic(e.target.value)}
            >
              <option value=''>{'\u00A0'}</option>
              <option value='4'>Extremely Experienced</option>
              <option value='3'>Very Experienced</option>
              <option value='2'>Moderately Experienced</option>
              <option value='1'>Less than Moderately Experienced</option>
            </select>
          </div>
        </div>


        <div className='form-row'>
          <div>
            <label className='input-label'>Primary Specialty Area<span className='required-indicator'>*</span></label>
            <select
              value={expOther1}
              onChange={(e) => setExpOther1(e.target.value)}
            >
              <option value=''>{'\u00A0'}</option>
              {areas.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label className='input-label'>Secondary Specialty Area</label>
            <select
              value={expOther2}
              onChange={(e) => setExpOther2(e.target.value)}
            >
              <option value=''>{'\u00A0'}</option>
              {areas.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className='form-row'>
          <div>
            <label className='input-label'>Other Specialty Area</label>
            <select value={expOther3} onChange={(e) => setExpOther3(e.target.value)}>
              <option value=''>{'\u00A0'}</option>
              {areas.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label className='input-label'>Other Specialty Area</label>
            <select
              value={expOther4}
              onChange={(e) => setExpOther4(e.target.value)}
            >
              <option value=''>{'\u00A0'}</option>
              {areas.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* <div className='form-row'>
          <div className='slider-container'>
            <p className='slider-label'>Years of Experience 1:</p>
            <input
              type='range'
              min='0'
              max='10'
              value={yearsExperience1}
              onChange={(e) => setYearsExperience1(e.target.value)}
              className='slider'
            />
            <span className='slider-value'>{yearsExperience1}</span>
          </div>
          <div className='slider-container'>
            <p className='slider-label'>Years of Experience 2:</p>
            <input
              type='range'
              min='0'
              max='10'
              value={yearsExperience2}
              onChange={(e) => setYearsExperience2(e.target.value)}
              className='slider'
            />
            <span className='slider-value'>{yearsExperience2}</span>
          </div>
        </div> */}
        <div className='form-row'>
          <div>
            <label className='input-label'>Primary Industry Experience<span className='required-indicator'>*</span></label>
            <select
              value={primaryIndustry}
              onChange={(e) => setPrimaryIndustry(e.target.value)}
            >
              <option value=''>{'\u00A0'}</option>
              {industries.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label className='input-label'>Secondary Industry Experience</label>
            <select
              value={secondaryIndustry}
              onChange={(e) => setSecondaryIndustry(e.target.value)}
            >
              <option value=''>{'\u00A0'}</option>
              {industries.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className='form-row space-between-rows'>
          <div>
            <label className='input-label'>Other Industry Experience</label>
            <select
              value={otherIndustry1}
              onChange={(e) => setOtherIndustry1(e.target.value)}
            >
              <option value=''>{'\u00A0'}</option>
              {industries.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label className='input-label'>Other Industry Experience</label>
            <select
              value={otherIndustry2}
              onChange={(e) => setOtherIndustry2(e.target.value)}
            >
              <option value=''>{'\u00A0'}</option>
              {industries.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className='form-row '>
          <div>
            <label className='input-label'>Primary Role</label>
            <select
              value={primaryRole}
              onChange={(e) => setPrimaryRole(e.target.value)}
            >
              <option value=''>{'\u00A0'}</option>
              {roles.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label className='input-label'>Secondary Role</label>
            <select
              value={secondaryRole}
              onChange={(e) => setSecondaryRole(e.target.value)}
            >
              <option value=''>{'\u00A0'}</option>
              {roles.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* <div className='form-row'>
          <div>
            <label className='input-label'>Credentials</label>
            <select
              value={credentials}
              onChange={(e) => setCredentials(e.target.value)}
              disabled
            >
              <option value=''>{'\u00A0'}</option>
              <option value='CPA'>CPA</option>
              <option value='JD'>JD</option>
              <option value='Masters'>Masters</option>
              <option value='PhD'>PhD</option>
            </select>
          </div>
        </div> */}


        <div className='form-row'>
          <div>
            <label className='input-label'>Primary IRS Organization with Interactions<span className='required-indicator'>*</span></label>
            <select
              value={primaryIrs}
              onChange={(e) => {
                if (e.target.value === 'Other') {
                  setPrimaryIrs(e.target.value);
                } else {
                  setPrimaryIrs(e.target.value);
                  setOtherIrs1('');
                }
              }}
            >
              <option value=''>{'\u00A0'}</option>
              {irsOrgs.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            {primaryIrs === 'Other' && (
              <div>
                <label className='input-label'>Other Organization</label>
                <input
                  type='text'
                  value={otherIrs1}
                  onChange={(e) => setOtherIrs1(e.target.value)}
                />
              </div>
            )}
          </div>
          <div>
            <label className='input-label'>Secondary IRS Organization with Interactions<span className='required-indicator'>*</span></label>
            <select
              value={secondaryIrs}
              onChange={(e) => {
                if (e.target.value === 'Other') {
                  setSecondaryIrs(e.target.value);
                } else {
                  setSecondaryIrs(e.target.value);
                  setOtherIrs2('');
                }
              }}
            >
              <option value=''>{'\u00A0'}</option>
              {irsOrgs.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            {secondaryIrs === 'Other' && (
              <div>
                <label className='input-label'>Other Organization</label>
                <input
                  type='text'
                  value={otherIrs2}
                  onChange={(e) => setOtherIrs2(e.target.value)}
                />
              </div>
            )}
          </div>
        </div>


        <div className='form-row'>
          <div>
            <label className='input-label'>Credentials</label>
            <Select
              isMulti
              value={credentials}
              onChange={handleSelectChange}
              options={options}
            />

          </div>
        </div>




        <div className='form-row'>
          <div className='centerlabel-container'> {/* Add a container div */}
            <label>Have you taught at the college/university level?</label>
          </div>
        </div>

        <div className='form-row  space-between-rows'>
          <div>
            <label className='input-label'>If yes, please list the name(s) of the institution(s) for which you taught</label>
            <input
              type='text'
              placeholder=''
              value={institution}
              onChange={(e) => setInstitution(e.target.value)}
            />
          </div>
          <div>
            <label className='input-label'>If yes, please list the college course names/topics you have taught</label>
            <input
              type='text'
              placeholder=''
              value={courses}
              onChange={(e) => setCourses(e.target.value)}
            />
          </div>
        </div>


        <div className='form-row'>
          <div>
            <label className='input-label'>Expected Availablity<span className='required-indicator'>*</span></label>
            <select
              value={availablity}
              onChange={(e) => setAvailablity(e.target.value)}
            >
              <option value=''>{'\u00A0'}</option>
              <option value='5'>80% to 100% available</option>
              <option value='4'>60% to 80% available</option>
              <option value='3'>40% to 60% available</option>
              <option value='2'>20% to 40% available</option>
              <option value='1'>0% to 20% available</option>
            </select>
          </div>
          <div>
            <label className='input-label'>Availablity to Travel<span className='required-indicator'>*</span></label>
            <select
              value={availablityToTravel}
              onChange={(e) => setAvailablityToTravel(e.target.value)}
            >
              <option value=''>{'\u00A0'}</option>
              <option value='5'>80% to 100% available</option>
              <option value='4'>60% to 80% available</option>
              <option value='3'>40% to 60% available</option>
              <option value='2'>20% to 40% available</option>
              <option value='1'>0% to 20% available</option>
            </select>
          </div>
        </div>

        <div className='form-row'>
          <div>
            <label className='input-label'>
              Next Availablity (start)
  <span className='required-indicator'>*</span>
            </label>
            <input
              type='text'
              placeholder=''
              value={availablityStartDateString}
              onChange={(e) => setAvailablityStartDateString(e.target.value)}
            />
          </div>
          <div>
            <label className='input-label'>Next Availablity (end)<span className='required-indicator'>*</span></label>
            <input
              type='text'
              placeholder=''
              value={availablityEndDateString}
              onChange={(e) => setAvailablityEndDateString(e.target.value)}
            />
          </div>
        </div>

        <div className='form-row'>
          <div>

            <label className='input-label'>Labor Category</label>
            <select
              value={laborCategory}
              onChange={(e) => setLaborCategory(e.target.value)}
            >
              <option value=''>{'\u00A0'}</option>
              {laborCategories.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label className='input-label'>
              Pay Rate
            </label>
            <input
              type='text'
              placeholder=''
              value={payRate}
              onChange={(e) => setPayRate(e.target.value)}
            />
          </div>
          <div>
            <label className='input-label'>Billing Rate</label>
            <input
              type='text'
              placeholder=''
              value={billingRate}
              onChange={(e) => setBillingRate(e.target.value)}
            />
          </div>
        </div>


        {resumeName !== "" && (
          <div className="button-container1">
            <button className="new-button1" onClick={() => handleDownload(userId)}>
              {resumeName ? resumeName : 'No Resume'}
            </button>
          </div>
        )}



        <button className='save-button' onClick={writeUserData}>
          Submit
        </button>
        <br />
        <br />
        <div className="sign-out-container">
          <button className="sign-out-button" onClick={() => navigate('/admin', { state: { isAdmin: true } })}>
            Back
  </button>
        </div>
      </div>
    </div>
  );
}

export default AdminProfile;
