import {Navigate, useLocation} from 'react-router-dom'
import { useState,  useEffect } from 'react';
import { ref, set, child, get } from "firebase/database";
import { getDatabase } from "firebase/database";
import AdminQuery from './AdminQuery';

function Admin() {
    const {state} = useLocation();
    if (!state.isAdmin) {
        return <h1>Unauthorized</h1>
    } 

    const database = getDatabase();
    return (
    <AdminQuery database={database} />
    )
}

export default Admin