import { initializeApp } from 'firebase/app'
import {getAuth} from 'firebase/auth'
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';


const firebaseConfig = {
  apiKey: "AIzaSyCCnCCZnsNW28UELVODj-4Vlj3b1UETY4c",
  authDomain: "webapp-2e211.firebaseapp.com",
  projectId: "webapp-2e211",
  storageBucket: "webapp-2e211.appspot.com",
  messagingSenderId: "750983240747",
  appId: "1:750983240747:web:70622e9718629b6e209e6e",
  measurementId: "G-6KKHMR7384"
};

// Initialize Firebase and Firebase Authentication
const app = initializeApp(firebaseConfig)
const auth = getAuth(app)

const uploadFile = async (file, fileName) => {
  try {
    // Get a reference to the Firebase Storage bucket
    const storage = getStorage();


    // Create a reference to the file location in the storage bucket
    const storageRef = ref(storage, fileName);

    // Upload the file to the storage location
    await uploadBytes(storageRef, file);

    // File uploaded successfully
    console.log('File uploaded successfully!');
  } catch (error) {
    // Error uploading file
    console.error('Error uploading file:', error);
  }
};

const handleDownload = async (fileName) => {
  try {
    const storage = getStorage();
    const fileRef = ref(storage, fileName);

    // Get the download URL for the file
    const downloadURL = await getDownloadURL(fileRef);

    // Create a link and simulate a click to trigger the file download
    const link = document.createElement('a');
    link.href = downloadURL;
    link.download = fileName;
    link.target = "_blank"; // Open in a new tab
    link.rel = "noopener noreferrer"; // Security best practice for target="_blank"
    link.click();
  } catch (error) {
    console.error('Error downloading file:', error);
    alert('File does not exist.');
  }
};



export {auth, uploadFile, handleDownload}
