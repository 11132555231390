import { useState, useEffect } from 'react';
import { ref, get, set } from 'firebase/database';
import { getDatabase } from 'firebase/database';
import { useLocation, useNavigate } from 'react-router-dom';
import './adminusers.css'; // Import the custom CSS file
import AdminQuery from './AdminQuery';

function AdminUsers() {
  const [users, setUsers] = useState([]);
  const database = getDatabase();
  const [newString, setNewString] = useState('');
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate('/admin', { state: { isAdmin: true } });
  };

  const deleteItemFromFirebase = (index) => {
    const newArray = [...users];
    newArray.splice(index, 1); // Remove the item at the given index
    setUsers(newArray);

    // Use an object with autogenerated keys to represent the updated array
    const arrayData = {};
    newArray.forEach((item, index) => {
      arrayData[index] = item;
    });

    set(ref(database, 'allowed-users/'), arrayData)
      .then(() => {
        console.log('Item deleted successfully.');
      })
      .catch((error) => {
        console.error('Error deleting item: ', error);
      });
  };

  const saveUsers = () => {
    // Update the local users array
    const updatedUsers = [...users, newString];
    setUsers(updatedUsers);
    setNewString('');

    // Use an object with autogenerated keys to represent the array
    const arrayData = {};
    updatedUsers.forEach((item, index) => {
      arrayData[index] = item;
    });

    // Save the updated array to Firebase
    set(ref(database, 'allowed-users/'), arrayData)
      .then(() => {
      })
      .catch((error) => {
        console.error('Error saving array: ', error);
      });
  };

  const getUsers = () => {
    const dbRef = ref(database, 'allowed-users/');
    get(dbRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          const newArray = Object.values(data).sort(); // Sort the array alphabetically
          setUsers(newArray);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getUsers();
  }, []);

  const { state } = useLocation();
  if (!state.isAdmin) {
    return <h1>Unauthorized</h1>;
  }

  return (
    <div className="admin-users-component">
      <h2 className="admin-users-title">Allowed Users</h2>
      {/* Add the back button */}
      <button className="back-btn" onClick={handleGoBack}>Back</button>
      <div className="input-section">
        <div className="input-container">
          <input
            type="text"
            value={newString}
            onChange={(e) => setNewString(e.target.value)}
            placeholder="Enter a string"
          />
          <button className="save-users-btn" onClick={saveUsers}>
            Save Email
          </button>
        </div>
      </div>
      <table className="users-table">
        <thead>
          <tr>
            <th>Emails</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {users.map((item, index) => (
            <tr key={index}>
              <td>{item}</td>
              <td>
                <button
                  className="delete-btn"
                  onClick={() => deleteItemFromFirebase(index)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <p className="note-text">Note: This only deletes from the allowed emails to sign up. If they have already made an account, you will have to inactivate them from the previous page.</p>
    </div>
  );
}

export default AdminUsers;
