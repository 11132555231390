import React, { useState } from 'react';
import { ref, child, get, set } from 'firebase/database';
import { useNavigate, useLocation } from 'react-router-dom';
import { handleDownload } from './firebase';



import './adminquery.css';

function AdminQuery({ database }) {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [previousEmployer, setPreviousEmployer] = useState('');
  const [everWorkedFor, setEverWorkedFor] = useState('');
  const [currentEmployer, setCurrentEmployer] = useState('');
  const [yearsExperience1FilterType, setYearsExperience1FilterType] = useState('');
  const [yearsExperience1Value, setYearsExperience1Value] = useState('');
  const [yearsExperience2FilterType, setYearsExperience2FilterType] = useState('');
  const [yearsExperience2Value, setYearsExperience2Value] = useState('');

  const [expPartnershipFilterType, setExpPartnershipFilterType] = useState('');
  const [expPartnershipValue, setExpPartnershipValue] = useState('');
  const [expDomesticFilterType, setExpDomesticFilterType] = useState('');
  const [expDomesticValue, setExpDomesticValue] = useState('');
  const [expHighWealthFilterType, setExpHighWealthFilterType] = useState('');
  const [expHighWealthValue, setExpHighWealthValue] = useState('');
  const [expMultiNationalFilterType, setExpMultiNationalFilterType] = useState('');
  const [expMultiNationalValue, setExpMultiNationalValue] = useState('');


  const [availablityType, setAvailabilityType] = useState('');
  const [availablityValue, setAvailabilityValue] = useState('');
  const [travelAvailablityType, setTravelAvailablityType] = useState('');
  const [travelAvailabilityValue, setTravelAvailabilityValue] = useState('');


  const [primaryIndustry, setPrimaryIndustry] = useState('');
  const [secondaryIndustry, setSecondaryIndustry] = useState('');
  const [otherIndustry, setOtherIndustry] = useState('');

  const [primarySpeciality, setPrimarySpecialty] = useState('');
  const [secondarySpecialty, setSecondarySpecialty] = useState('');
  const [otherSpecialty, setOtherSpecialty] = useState('');

  const [industry, setIndustry] = useState('');
  const [specialty, setSpecialty] = useState('');

  const [start, setStart] = useState('');
  const [end, setEnd] = useState('');

  const [laborCategoryType, setLaborCategoryType] = useState('');
  const [laborCategoryValue, setLaborCategoryValue] = useState('');
  const [payRateType, setPayRateType] = useState('');
  const [payRateValue, setPayRateValue] = useState('');
  const [billinngRateType, setBillingRateType] = useState('');
  const [billingRateValue, setBillingRateValue] = useState('');

  const [primaryRole, setPrimaryRole] = useState("");
  const [secondaryRole, setSecondaryRole] = useState("");


  const [agency, setAgency] = useState('');
  const [otherAgency, setOtherAgency] = useState('');

  const [yearsFederalType, setYearsFederalType] = useState('');
  const [yearsFederalValue, setYearsFederalValue] = useState('');

  const [primaryIrs, setPrimaryIrs] = useState('');
  const [otherIrs1, setOtherIrs1] = useState('');
  const [secondaryIrs, setSecondaryIrs] = useState('');
  const [otherIrs2, setOtherIrs2] = useState('');

  const [institution, setInstitution] = useState('');

  const [queryAttributes, setQueryAttributes] = useState([]);
  const [results, setResults] = useState([]);
  const navigate = useNavigate();



  const industries = [
    'Automotive',
    'Banking & Capital Markets',
    'Construction',
    'Consumer Products',
    'Distribution',
    'Engineering',
    'Exempt Organizations',
    'Financial Services',
    'Hospitality',
    'Life Sciences',
    'Manufacturing',
    'Oil & Gas',
    'Professional Services',
    'Real Estate',
    'Retail',
    'Sports and Entertainment',
    'Technology',
    'Telecommunications',
    'Wealth & Asset Management'
  ];


  const areas = [
    'Auditing (financial statements)',
    'Corporate structuring',
    'Cross border restructuring',
    'Data analytics',
    'Domectis passthroughs',
    'Foreign Account Tax Compliant Act (FATCA)',
    'Foreign Investment in Real Property Tax Act (FIRPTA)',
    'Foreign Tax Credit',
    'Hedge funds',
    'High wealth families',
    'International Financial Reporting Standards (IFRS)',
    'High wealth individuals',
    'Large Multi-Nationals',
    'M&A due diligence',
    'Passive foreign investment companies (PFIC)',
    'Post-transaction integrations',
    'Pre-Filing Program',
    'Private Equity',
    'Repatriation Planning',
    'Sovereign wealth funds',
    'Statistical modeling',
    'Statistical sampling',
    'Tax controversy and dispute resolution',
    'Tax effective supply chain planning',
    'Territories (U.S.) and issues related to',
    'Transaction tax Carve Outs',
    'Transfer pricing'
  ];



  const firms = [
    'BDO',
    'Deloitte',
    'Ernst & Young',
    'Grant Thornton',
    'KPMG',
    'PwC',
    'RSM',
    'Other'
  ];

  const laborCategories = [
    "Tier 1",
    "Tier 2",
    "Tier 3",
    "Tier 4",
    "Tier 5",
    "Tier 6",
    "Tier 7",
    "Tier 8",
    "Program Manager",
    "Deputy Program Manager",
    "PMO Staff"
  ];

  const expMapping = {
    '4': 'Extremely Experienced',
    '3': 'Very Experienced',
    '2': 'Moderately Experienced',
    '1': 'Less than Moderately Experienced'
  };

  const availabilityMapping = {
    '5': '80% to 100% available',
    '4': '60% to 80% available',
    '3': '40% to 60% available',
    '2': '20% to 40% available',
    '1': '0% to 20% available'
  };

  const availabilityTravelMapping = {
    '6': '80% to 100% available',
    '5': '60% to 80% available',
    '4': '40% to 60% available',
    '3': '20% to 40% available',
    '2': '1% to 20% available',
    '1': '0% available'
  };


  const roles = [
    'Audit',
    'Planning',
    'Controversy',
    'Other'
  ]

  const agencies = [
    'Internal Revenue Service',
    'Department of the Treasury (non-IRS)',
    'Department of Justice',
    'Any of the relevant Tax Committees in Congress',
    'Other'
  ];

  const irsOrgs = [
    'Large Business & International (LB&I)',
    'Small Business/Self Employed (SBSE)',
    'Tax Exempt/Government Entities (TEGE)',
    'Wage & Investment (W&I)',
    'Office of Appeals',
    'Office of Chief Counsel',
    'Other'
  ];

  const createCSVContent = () => {
    // Get the headers from the queryAttributes array
    const headers = queryAttributes.map(attribute => attribute.label);

    // Generate the rows content
    const rows = results.map(([userId, userData]) =>
      queryAttributes.map(attribute =>
        renderCellValue(attribute, userData)
      )
    );

    // Create the CSV content
    const csvContent = [headers.join(','), ...rows.map(row => row.join(','))].join('\n');

    return csvContent;
  };


  const handleDownloadCSV = () => {
    const csvContent = createCSVContent();

    // Create a new Blob object with the CSV content
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

    // Create a temporary anchor element to trigger the download
    const link = document.createElement('a');
    if (link.download !== undefined) {
      // Set the download attribute and URL of the anchor element
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'table-results.csv');

      // Append the anchor element to the document body and click it
      document.body.appendChild(link);
      link.click();

      // Remove the anchor element
      document.body.removeChild(link);

      // Clean up the URL object
      URL.revokeObjectURL(url);
    }
  };


  const inactivateUser = (userId) => {
    const dbRef = ref(database);
    get(child(dbRef, 'users'))
      .then((snapshot) => {
        if (snapshot.exists()) {
          const users = snapshot.val();
          const filteredResults = Object.entries(users).filter(([id, userData]) => id === userId);

          if (filteredResults.length > 0) {
            const [matchingUserId, matchingUserData] = filteredResults[0];
            matchingUserData.inactive = 'yes';

            set(child(dbRef, `users/${matchingUserId}`), matchingUserData)
              .then(() => {
                console.log('User inactivated successfully.');
                runQuery();
              })
              .catch((error) => {
                console.error('Error inactivating user:', error);
              });
          } else {
            console.log('User not found in the database.');
          }
        }
      })
      .catch((error) => {
        console.error('Error retrieving users:', error);
      });
  };





  const runQuery = () => {
    const dbRef = ref(database);
    get(child(dbRef, 'users'))
      .then((snapshot) => {
        if (snapshot.exists()) {
          const users = snapshot.val();
          const filteredResults = Object.entries(users).filter(([userId, userData]) => {
            const isFirstNameMatch = firstName === '' || userData.firstName === firstName;
            const isLastNameMatch = lastName === '' || userData.lastName === lastName;
            const previousEmployerMatch =
              previousEmployer === '' || userData.previousEmployer === previousEmployer;
            const everWorkedForMatch =
              everWorkedFor === '' ||
              [userData.previousEmployer, userData.previousEmployer2, userData.previousEmployer3].includes(
                everWorkedFor
              );
            const isCurrentEmployerMatch = currentEmployer === '' || userData.currentEmployer === currentEmployer;


            const isPrimaryIndustryMatch = primaryIndustry === '' || userData.primaryIndustry === primaryIndustry;
            const isSecondaryIndustryMatch =
              secondaryIndustry === '' || userData.secondaryIndustry === secondaryIndustry;
            const isOtherIndustryMatch =
              otherIndustry === '' ||
              [userData.otherIndustry1, userData.otherIndustry2].includes(
                otherIndustry
              );

            const isIndustryMatch = industry === '' ||
              [userData.primaryIndustry, userData.secondaryIndustry, userData.otherIndustry1, userData.otherIndustry2].includes(
                industry
              );

            const isPrimarySpecialtyyMatch = primarySpeciality === '' || userData.expOther1 === primarySpeciality;
            const isSecondarySpecialtyMatch =
              secondarySpecialty === '' || userData.expOther2 === secondarySpecialty;
            const isOtherSpecialtyMatch =
              otherSpecialty === '' ||
              [userData.expOther3, userData.expOther4].includes(
                otherSpecialty
              );

            const isSpecialtyMatch = specialty === '' ||
              [userData.expOther1, userData.expOther2, userData.expOther3, userData.expOther4].includes(
                specialty
              );

            const isPrimaryRoleMatch = primaryRole === '' || userData.primaryRole === primaryRole;
            const isSecondaryRoleMatch = secondaryRole === '' || userData.secondaryRole === secondaryRole;

            let isYearsExperience1Match = true;

            if (yearsExperience1FilterType !== '') {
              const yearsExperience1 = parseInt(userData.yearsExperience1, 10);
              const value = parseInt(yearsExperience1Value, 10);

              switch (yearsExperience1FilterType) {
                case 'moreThan':
                  isYearsExperience1Match = yearsExperience1 > value;
                  break;
                case 'equalTo':
                  isYearsExperience1Match = yearsExperience1 === value;
                  break;
                case 'lessThan':
                  isYearsExperience1Match = yearsExperience1 < value;
                  break;
                case 'between':
                  const [lowerValue, upperValue] = yearsExperience1Value.split('-').map(Number);
                  isYearsExperience1Match = yearsExperience1 >= lowerValue && yearsExperience1 <= upperValue;
                  break;
                default:
                  isYearsExperience1Match = true;
                  break;
              }
            }

            let isYearsExperience2Match = true;

            if (yearsExperience2FilterType !== '') {
              const yearsExperience2 = parseInt(userData.yearsExperience2, 10);
              const value = parseInt(yearsExperience2Value, 10);

              switch (yearsExperience2FilterType) {
                case 'moreThan':
                  isYearsExperience2Match = yearsExperience2 > value;
                  break;
                case 'equalTo':
                  isYearsExperience2Match = yearsExperience2 === value;
                  break;
                case 'lessThan':
                  isYearsExperience2Match = yearsExperience2 < value;
                  break;
                case 'between':
                  const [lowerValue, upperValue] = yearsExperience1Value.split('-').map(Number);
                  isYearsExperience2Match = yearsExperience2 >= lowerValue && yearsExperience2 <= upperValue;
                  break;
                default:
                  isYearsExperience2Match = true;
                  break;
              }
            }

            let isExpPartnershipMatch = true;

            if (expPartnershipFilterType !== '') {
              const expPartnership = parseInt(userData.expPartnership, 10);
              const value = parseInt(expPartnershipValue, 10);

              switch (expPartnershipFilterType) {
                case 'moreThan':
                  isExpPartnershipMatch = expPartnership > value;
                  break;
                case 'equalTo':
                  isExpPartnershipMatch = expPartnership === value;
                  break;
                case 'lessThan':
                  isExpPartnershipMatch = expPartnership < value;
                  break;
                case 'between':
                  const [lowerValue, upperValue] = expPartnershipValue.split('-').map(Number);
                  isExpPartnershipMatch = expPartnership >= lowerValue && expPartnership <= upperValue;
                  break;
                default:
                  isExpPartnershipMatch = true;
                  break;
              }
            }

            let isExpDomesticMatch = true;

            if (expDomesticFilterType !== '') {
              const expDomestic = parseInt(userData.expDomestic, 10);
              const value = parseInt(expDomesticValue, 10);

              switch (expDomesticFilterType) {
                case 'moreThan':
                  isExpDomesticMatch = expDomestic > value;
                  break;
                case 'equalTo':
                  isExpDomesticMatch = expDomestic === value;
                  break;
                case 'lessThan':
                  isExpDomesticMatch = expDomestic < value;
                  break;
                case 'between':
                  const [lowerValue, upperValue] = expDomesticValue.split('-').map(Number);
                  isExpDomesticMatch = expDomestic >= lowerValue && expDomestic <= upperValue;
                  break;
                default:
                  isExpDomesticMatch = true;
                  break;
              }
            }



            let isExpMultiNationalMatch = true;

            if (expMultiNationalFilterType !== '') {
              const expMultiNational = parseInt(userData.expMultiNational, 10);
              const value = parseInt(expMultiNationalValue, 10);

              switch (expMultiNationalFilterType) {
                case 'moreThan':
                  isExpMultiNationalMatch = expMultiNational > value;
                  break;
                case 'equalTo':
                  isExpMultiNationalMatch = expMultiNational === value;
                  break;
                case 'lessThan':
                  isExpMultiNationalMatch = expMultiNational < value;
                  break;
                case 'between':
                  const [lowerValue, upperValue] = expMultiNationalValue.split('-').map(Number);
                  isExpMultiNationalMatch = expMultiNational >= lowerValue && expMultiNational <= upperValue;
                  break;
                default:
                  isExpMultiNationalMatch = true;
                  break;
              }
            }

            let isExpHighWealthMatch = true;

            if (expHighWealthFilterType !== '') {
              const expHighWealth = parseInt(userData.expHighWealth, 10);
              const value = parseInt(expHighWealthValue, 10);

              switch (expHighWealthFilterType) {
                case 'moreThan':
                  isExpHighWealthMatch = expHighWealth > value;
                  break;
                case 'equalTo':
                  isExpHighWealthMatch = expHighWealth === value;
                  break;
                case 'lessThan':
                  isExpHighWealthMatch = expHighWealth < value;
                  break;
                case 'between':
                  const [lowerValue, upperValue] = expHighWealthValue.split('-').map(Number);
                  isExpHighWealthMatch = expHighWealth >= lowerValue && expHighWealth <= upperValue;
                  break;
                default:
                  isExpHighWealthMatch = true;
                  break;
              }
            }



            let isAvailabilityMatch = true;

            if (availablityType !== '') {
              const availability = parseInt(userData.availablity, 10);
              const value = parseInt(availablityValue, 10);
              console.log("user availability")
              console.log(availability)
              console.log("admin availability")
              console.log(value)
              switch (availablityType) {
                case 'moreThan':
                  isAvailabilityMatch = availability > value;
                  break;
                case 'equalTo':
                  isAvailabilityMatch = availability === value;
                  break;
                case 'lessThan':
                  isAvailabilityMatch = availability < value;
                  break;
                default:
                  isAvailabilityMatch = true;
                  break;
              }
            }

            let isTravelAvailablityMatch = true;

            if (travelAvailablityType !== '') {
              const availabilityToTravel = parseInt(userData.availablityToTravel, 10);
              const value = parseInt(travelAvailabilityValue, 10);



              switch (travelAvailablityType) {
                case 'moreThan':
                  isTravelAvailablityMatch = availabilityToTravel > value;
                  break;
                case 'equalTo':
                  isTravelAvailablityMatch = availabilityToTravel === value;
                  break;
                case 'lessThan':
                  isTravelAvailablityMatch = availabilityToTravel < value;
                  break;
                default:
                  isTravelAvailablityMatch = true;
                  break;
              }
            }

            const isStartMatch = start === '' || userData.availablityStartDate > start;
            const isEndMatch = end === '' || userData.availablityEndDate > end;



            let isLaborCategoryMatch = true;

            if (laborCategoryType !== '') {
              const laborCategory = parseInt(userData.laborCategory, 10);

              console.log("user labor category")
              console.log(laborCategory)

              const value = parseInt(laborCategoryValue, 10);
              console.log("admin labor category")
              console.log(value)


              switch (laborCategoryType) {
                case 'moreThan':
                  isLaborCategoryMatch = laborCategory > value;
                  break;
                case 'equalTo':
                  isLaborCategoryMatch = laborCategory === value;
                  break;
                case 'lessThan':
                  isLaborCategoryMatch = laborCategory < value;
                  break;
                default:
                  isLaborCategoryMatch = true;
                  break;
              }
            }



            let isPayRateMatch = true;

            if (payRateType !== '') {
              const payRate = parseInt(userData.payRate, 10);
              const value = parseInt(payRateValue, 10);

              switch (payRateType) {
                case 'moreThan':
                  isPayRateMatch = payRate > value;
                  break;
                case 'equalTo':
                  isPayRateMatch = payRate === value;
                  break;
                case 'lessThan':
                  isPayRateMatch = payRate < value;
                  break;
                default:
                  isPayRateMatch = true;
                  break;
              }
            }

            let isBillingRateMatch = true;

            if (billinngRateType !== '') {
              const billingRate = parseInt(userData.billingRate, 10);
              const value = parseInt(billingRateValue, 10);

              switch (billinngRateType) {
                case 'moreThan':
                  isBillingRateMatch = billingRate > value;
                  break;
                case 'equalTo':
                  isBillingRateMatch = billingRate === value;
                  break;
                case 'lessThan':
                  isBillingRateMatch = billingRate < value;
                  break;
                default:
                  isBillingRateMatch = true;
                  break;
              }
            }

            const isInactive = userData.inactive !== 'yes';

            const isAgencyMatch = agency === '' || userData.agency === agency;

            let isYearsFederalMatch = true;

            if (yearsFederalType !== '') {
              const yearsFederal = parseInt(userData.yearsFederal, 10);
              const value = parseInt(yearsFederalValue, 10);

              switch (yearsFederalType) {
                case 'moreThan':
                  isYearsFederalMatch = yearsFederal > value;
                  break;
                case 'equalTo':
                  isYearsFederalMatch = yearsFederal === value;
                  break;
                case 'lessThan':
                  isYearsFederalMatch = yearsFederal < value;
                  break;
                default:
                  isYearsFederalMatch = true;
                  break;
              }
            }

            const isPrimaryIrsMatch = primaryIrs === '' || userData.primaryIrs === primaryIrs;
            const isSecondaryIrsMatch = secondaryIrs === '' || userData.secondaryIrs === secondaryIrs;
            const isInstitutionMatch = institution === '' || userData.institution === institution;


            // const queryAttributes = [
            //   { label: 'First Name', value: 'firstName' },
            //   { label: 'Last Name', value: 'lastName' },
            //   { label: 'Years Exp Tax', value: 'yearsExperience1' },
            //   { label: 'Years Exp Partner', value: 'yearsExperience2' },

            //   (currentEmployer ? { label: 'Current Employer', value: 'currentEmployer'} : undefined),
            //   (previousEmployer || everWorkedFor ? { label: 'Previous Employer', value: 'previousEmployer'} : undefined),
            //   (previousEmployer || everWorkedFor ? { label: 'Previous Employer2', value: 'previousEmployer2'} : undefined),
            //   (previousEmployer || everWorkedFor ? { label: 'Previous Employer3', value: 'previousEmployer3'} : undefined),

            // ].filter((attribute) => attribute.value !== '');

            const queryAttributes = [];

            queryAttributes.push({ label: 'First Name', value: 'firstName' });

            queryAttributes.push({ label: 'Last Name', value: 'lastName' });



            queryAttributes.push({ label: 'Years Tax Experience', value: 'yearsExperience1' });


            queryAttributes.push({ label: 'Years Partner Experience', value: 'yearsExperience2' });


            queryAttributes.push({ label: 'Credentials', value: 'credentials' });


            queryAttributes.push({ label: 'Previous Employer', value: 'previousEmployer' });


            queryAttributes.push({ label: 'Labor Category', value: 'laborCategory' });
            queryAttributes.push({ label: 'Pay Rate', value: 'payRate' });
            queryAttributes.push({ label: 'Billing Rate', value: 'billingRate' });

            if (currentEmployer) {
              queryAttributes.push({ label: 'Current Employer', value: 'currentEmployer' });
            }


            if (previousEmployer || everWorkedFor) {
              queryAttributes.push({ label: 'Previous Employer 2', value: 'previousEmployer2' });
            }

            if (previousEmployer || everWorkedFor) {
              queryAttributes.push({ label: 'Previous Employer 3', value: 'previousEmployer3' });
            }

            if (expPartnershipValue) {
              queryAttributes.push({ label: 'Exp Partnership', value: 'expPartnership' });
            }
            if (expHighWealthValue) {
              queryAttributes.push({ label: 'Exp High Wealth', value: 'expHighWealth' });
            }
            if (expDomesticValue) {
              queryAttributes.push({ label: 'Exp Domestic', value: 'expDomestic' });
            }
            if (expMultiNationalValue) {
              queryAttributes.push({ label: 'Exp Multi National', value: 'expMultiNational' });
            }

            if (primaryIndustry) {
              queryAttributes.push({ label: 'Primary Industry', value: 'primaryIndustry' });
            }
            if (secondaryIndustry) {
              queryAttributes.push({ label: 'Secondary Industry', value: 'secondaryIndustry' });
            }
            if (otherIndustry) {
              queryAttributes.push({ label: 'Other Industry 1', value: 'otherIndustry1' });
              queryAttributes.push({ label: 'Other Industry 2', value: 'otherIndustry2' });
            }
            if (industry) {
              queryAttributes.push({ label: 'Primary Industry', value: 'primaryIndustry' });
              queryAttributes.push({ label: 'Secondary Industry', value: 'secondaryIndustry' });
              queryAttributes.push({ label: 'Other Industry 1', value: 'otherIndustry1' });
              queryAttributes.push({ label: 'Other Industry 2', value: 'otherIndustry2' });
            }

            if (primaryRole) {
              queryAttributes.push({ label: 'Primary Role', value: 'primaryRole' });
            }
            if (secondaryRole) {
              queryAttributes.push({ label: 'Secondary Role', value: 'secondaryRole' });
            }

            if (primarySpeciality) {
              queryAttributes.push({ label: 'Primary Specialty Area', value: 'expOther1' });
            }
            if (secondarySpecialty) {
              queryAttributes.push({ label: 'Secondary Specialty Area', value: 'expOther2' });
            }
            if (otherSpecialty) {
              queryAttributes.push({ label: 'Other Specialty Area 1', value: 'expOther3' });
              queryAttributes.push({ label: 'Other Specialty Area 2', value: 'expOther4' });
            }
            if (specialty) {
              queryAttributes.push({ label: 'Primary Specialty Area', value: 'expOther1' });
              queryAttributes.push({ label: 'Secondary Specialty Area', value: 'expOther2' });
              queryAttributes.push({ label: 'Other Specialty Area 1', value: 'expOther3' });
              queryAttributes.push({ label: 'Other Specialty Area 2', value: 'expOther4' });
            }



            if (availablityValue) {
              queryAttributes.push({ label: 'Availability', value: 'availablity' });
            }
            if (travelAvailabilityValue) {
              queryAttributes.push({ label: 'Availability to Travel', value: 'availablityToTravel' });
            }

            if (start) {
              queryAttributes.push({ label: 'Next Availability (Start)', value: 'availablitStartDate' });
            }
            if (end) {
              queryAttributes.push({ label: 'Next Availability (End)', value: 'availablityEndDate' });
            }


            if (agency) {
              queryAttributes.push({ label: 'Federal Agency', value: 'agency' });
              queryAttributes.push({ label: 'Other Agency', value: 'otherAgency' });
            }
            if (yearsFederalValue) {
              queryAttributes.push({ label: 'Years Exp Federal', value: 'yearsFederal' });
            }
            if (primaryIrs) {
              queryAttributes.push({ label: 'Primary IRS Agency', value: 'primaryIrs' });
              queryAttributes.push({ label: 'Other IRS Agency', value: 'otherIrs2' });
            }
            if (secondaryIrs) {
              queryAttributes.push({ label: 'Secondary IRS Agency', value: 'secondaryIrs' });
              queryAttributes.push({ label: 'Other IRS Agency', value: 'otherIrs2' });
            }
            if (institution) {
              queryAttributes.push({ label: 'Institution', value: 'institution' });
            }

            setQueryAttributes(queryAttributes);

            // console.log("isFirstNameMatch:", isFirstNameMatch);
            // console.log("isLastNameMatch:", isLastNameMatch);
            // console.log("previousEmployerMatch:", previousEmployerMatch);
            // console.log("everWorkedForMatch:", everWorkedForMatch);
            // console.log("isCurrentEmployerMatch:", isCurrentEmployerMatch);
            // console.log("isYearsExperience1Match:", isYearsExperience1Match);
            // console.log("isYearsExperience2Match:", isYearsExperience2Match);
            // console.log("isExpPartnershipMatch:", isExpPartnershipMatch);
            // console.log("isExpMultiNationalMatch:", isExpMultiNationalMatch);
            // console.log("isExpDomesticMatch:", isExpDomesticMatch);
            // console.log("isExpHighWealthMatch:", isExpHighWealthMatch);
            // console.log("isPrimaryIndustryMatch:", isPrimaryIndustryMatch);
            // console.log("isSecondaryIndustryMatch:", isSecondaryIndustryMatch);
            // console.log("isOtherIndustryMatch:", isOtherIndustryMatch);
            // console.log("isPrimarySpecialtyyMatch:", isPrimarySpecialtyyMatch);
            // console.log("isSecondarySpecialtyMatch:", isSecondarySpecialtyMatch);
            // console.log("isOtherSpecialtyMatch:", isOtherSpecialtyMatch);


            return (
              isFirstNameMatch &&
              isLastNameMatch &&
              previousEmployerMatch &&
              everWorkedForMatch &&
              isCurrentEmployerMatch &&
              isYearsExperience1Match &&
              isYearsExperience2Match &&
              isExpPartnershipMatch &&
              isExpMultiNationalMatch &&
              isExpDomesticMatch &&
              isExpHighWealthMatch &&
              isPrimaryIndustryMatch &&
              isSecondaryIndustryMatch &&
              isOtherIndustryMatch &&
              isIndustryMatch &&
              isPrimaryRoleMatch &&
              isSecondaryRoleMatch &&
              isPrimarySpecialtyyMatch &&
              isSecondarySpecialtyMatch &&
              isOtherSpecialtyMatch &&
              isSpecialtyMatch &&
              isAvailabilityMatch &&
              isTravelAvailablityMatch &&
              isStartMatch &&
              isEndMatch &&
              isLaborCategoryMatch &&
              isPayRateMatch &&
              isBillingRateMatch &&
              isInactive &&
              isAgencyMatch &&
              isYearsFederalMatch &&
              isPrimaryIrsMatch &&
              isSecondaryIrsMatch
              && isInstitutionMatch
            );

            // Add more filters for the remaining criteria
          });
          // setResults(filteredResults);

          const sortedResults = filteredResults.sort(([userIdA, userDataA], [userIdB, userDataB]) => {
            const lastNameA = userDataA.lastName || '';
            const lastNameB = userDataB.lastName || '';
            return lastNameA.localeCompare(lastNameB);
          });
          setResults(sortedResults)
        } else {
          setResults([]);
        }
      })
      .catch((error) => {
        console.error(error);
        setResults([]);
      });
  };

  const resetState = () => {
    setFirstName('');
    setLastName('');
    setPreviousEmployer('');
    setEverWorkedFor('');
    setCurrentEmployer('');
    setYearsExperience1FilterType('');
    setYearsExperience1Value('');
    setYearsExperience2FilterType('');
    setYearsExperience2Value('');
    setExpPartnershipFilterType('');
    setExpPartnershipValue('');
    setExpDomesticFilterType('');
    setExpDomesticValue('');
    setExpHighWealthFilterType('');
    setExpHighWealthValue('');
    setExpMultiNationalFilterType('');
    setExpMultiNationalValue('');
    setAvailabilityType('');
    setAvailabilityValue('');
    setTravelAvailablityType('');
    setTravelAvailabilityValue('');
    setPrimaryIndustry('');
    setSecondaryIndustry('');
    setOtherIndustry('');
    setPrimarySpecialty('');
    setSecondarySpecialty('');
    setOtherSpecialty('');
    setStart('');
    setEnd('');
    setLaborCategoryType('');
    setLaborCategoryValue('');
    setPayRateType('');
    setPayRateValue('');
    setBillingRateType('');
    setBillingRateValue('');
    setIndustry('');
    setSpecialty('');
    setPrimaryRole('');
    setSecondaryRole('');
    setAgency('');
    setYearsFederalType('');
    setYearsFederalValue('');
    setPrimaryIrs('');
    setSecondaryIrs('');
    setInstitution('');
  };


  const renderCellValue = (attribute, userData) => {
    switch (attribute.value) {
      case 'expMultiNational':
        return expMapping[userData[attribute.value]];
      case 'expHighWealth':
        return expMapping[userData[attribute.value]];
      case 'expPartnership':
        return expMapping[userData[attribute.value]];
      case 'expDomestic':
        return expMapping[userData[attribute.value]];
      case 'availablity':
        return availabilityMapping[userData[attribute.value]];
      case 'availablityToTravel':
        return availabilityTravelMapping[userData[attribute.value]];
      case 'credentials':
        if (userData[attribute.value] && userData[attribute.value].length > 0) {
          const selectedValues = userData[attribute.value].map(option => option.value);
          return selectedValues.join(', ');
        }
        return '';
      default:
        return userData[attribute.value];
    }
  };


  const handleBack = () => {
    navigate('/');
  };
  const handleGoToUserPage = () => {
    navigate('/admin-users', { state: { isAdmin: true } });
  };

  // const exportToCsv = () => {
  //   const csvContent = `data:text/csv;charset=utf-8,User ID,First Name,Last Name,Address 1,Address 2\n${results
  //     .map(([userId, userData]) => `${userId},${userData.firstName},${userData.lastName},${userData.address1},${userData.address2}`)
  //     .join('\n')}`;
  //   const encodedUri = encodeURI(csvContent);
  //   const link = document.createElement('a');
  //   link.setAttribute('href', encodedUri);
  //   link.setAttribute('download', 'query_results.csv');
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };

  return (
    <div className="query-component">
      <button className="back-btn" onClick={handleBack}>
        Back
      </button>
      <button className="users-btn" onClick={handleGoToUserPage}>
        Allowed Users
      </button>
      <div className="input-section">
        <div className="input-header">
          <button className="reset-button" onClick={resetState}>Reset</button>

          <h2 style={{ textAlign: 'center' }}>Input Query</h2>
        </div>
        <div className="row">
          <div className="attribute-inputs">
            <label>First Name:</label>
            <input type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
          </div>

          <div className="attribute-inputs">
            <label>Last Name:</label>
            <input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} />
          </div>
        </div>

        <div className="attribute-inputs">
          <label>Previous Employer:</label>
          <select
            value={previousEmployer}
            onChange={(e) => setPreviousEmployer(e.target.value)}
          >
            <option value=''>{'\u00A0'}</option>
            {firms.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>

        <div className="attribute-inputs">
          <label>Ever Worked For:</label>
          <select
            value={everWorkedFor}
            onChange={(e) => setEverWorkedFor(e.target.value)}
          >
            <option value=''>{'\u00A0'}</option>
            {firms.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>        </div>

        <div className="attribute-inputs">
          <label>Current Employer (none, self, or anything):</label>
          <input type="text" value={currentEmployer} onChange={(e) => setCurrentEmployer(e.target.value)} />
        </div>

        <div className="attribute-inputs">
          <label>Years of Tax Experience:</label>
          <select value={yearsExperience1FilterType} onChange={(e) => setYearsExperience1FilterType(e.target.value)}>
            <option value="">Ignore</option>
            <option value="moreThan">More Than</option>
            <option value="equalTo">Equal To</option>
            <option value="lessThan">Less Than</option>
            {/* <option value="between">Between</option> */}
          </select>
          {yearsExperience1FilterType === 'between' && (
            <input
              type="text"
              value={yearsExperience1Value}
              onChange={(e) => setYearsExperience1Value(e.target.value)}
              placeholder="Lower-Upper"
            />
          )}
          {yearsExperience1FilterType !== '' && (
            <input
              type="text"
              value={yearsExperience1Value}
              onChange={(e) => setYearsExperience1Value(e.target.value)}
              placeholder="Value"
            />
          )}
        </div>

        <div className="attribute-inputs">
          <label>Years as Partner:</label>
          <select value={yearsExperience2FilterType} onChange={(e) => setYearsExperience2FilterType(e.target.value)}>
            <option value="">Ignore</option>
            <option value="moreThan">More Than</option>
            <option value="equalTo">Equal To</option>
            <option value="lessThan">Less Than</option>
            {/* <option value="between">Between</option> */}
          </select>
          {yearsExperience2FilterType === 'between' && (
            <input
              type="text"
              value={yearsExperience2Value}
              onChange={(e) => setYearsExperience2Value(e.target.value)}
              placeholder="Lower-Upper"
            />
          )}
          {yearsExperience2FilterType !== '' && (
            <input
              type="text"
              value={yearsExperience2Value}
              onChange={(e) => setYearsExperience2Value(e.target.value)}
              placeholder="Value"
            />
          )}
        </div>

        <div className="attribute-inputs">
          <label>Exp Multi National:</label>
          <select value={expMultiNationalFilterType} onChange={(e) => setExpMultiNationalFilterType(e.target.value)}>
            <option value="">Ignore</option>
            <option value="moreThan">More Than</option>
            <option value="equalTo">Equal To</option>
            <option value="lessThan">Less Than</option>
            {/* <option value="between">Between</option> */}
          </select>
          {expMultiNationalFilterType !== '' && (
            <select
              value={expMultiNationalValue}
              onChange={(e) => setExpMultiNationalValue(e.target.value)}
            >
              <option value=''>{'\u00A0'}</option>
              <option value='4'>Extremely Experienced</option>
              <option value='3'>Very Experienced</option>
              <option value='2'>Moderately Experienced</option>
              <option value='1'>Less than Moderately Experienced</option>
            </select>
          )}
        </div>

        <div className="attribute-inputs">
          <label>Exp High Wealth:</label>
          <select value={expHighWealthFilterType} onChange={(e) => setExpHighWealthFilterType(e.target.value)}>
            <option value="">Ignore</option>
            <option value="moreThan">More Than</option>
            <option value="equalTo">Equal To</option>
            <option value="lessThan">Less Than</option>
          </select>
          {/* {expHighWealthFilterType === 'between' && (
            <input
              type="text"
              value={expHighWealthValue}
              onChange={(e) => setExpHighWealthValue(e.target.value)}
              placeholder="Lower-Upper"
            />
          )} */}
          {expHighWealthFilterType !== '' && (

            <select
              value={expHighWealthValue}
              onChange={(e) => setExpHighWealthValue(e.target.value)}
            >
              <option value=''>{'\u00A0'}</option>
              <option value='4'>Extremely Experienced</option>
              <option value='3'>Very Experienced</option>
              <option value='2'>Moderately Experienced</option>
              <option value='1'>Less than Moderately Experienced</option>
            </select>

            // <input
            //   type="text"
            //   value={expHighWealthValue}
            //   onChange={(e) => setExpHighWealthValue(e.target.value)}
            //   placeholder="Value"
            // />
          )}
        </div>


        <div className="attribute-inputs">
          <label>Exp Partnership:</label>
          <select value={expPartnershipFilterType} onChange={(e) => setExpPartnershipFilterType(e.target.value)}>
            <option value="">Ignore</option>
            <option value="moreThan">More Than</option>
            <option value="equalTo">Equal To</option>
            <option value="lessThan">Less Than</option>
            {/* <option value="between">Between</option> */}
          </select>
          {expPartnershipFilterType !== '' && (
            <select
              value={expPartnershipValue}
              onChange={(e) => setExpPartnershipValue(e.target.value)}
            >
              <option value=''>{'\u00A0'}</option>
              <option value='4'>Extremely Experienced</option>
              <option value='3'>Very Experienced</option>
              <option value='2'>Moderately Experienced</option>
              <option value='1'>Less than Moderately Experienced</option>
            </select>
          )}
        </div>

        <div className="attribute-inputs">
          <label>Exp Domestic:</label>
          <select value={expDomesticFilterType} onChange={(e) => setExpDomesticFilterType(e.target.value)}>
            <option value="">Ignore</option>
            <option value="moreThan">More Than</option>
            <option value="equalTo">Equal To</option>
            <option value="lessThan">Less Than</option>
            {/* <option value="between">Between</option> */}
          </select>
          {expDomesticFilterType !== '' && (
            <select
              value={expDomesticValue}
              onChange={(e) => setExpDomesticValue(e.target.value)}
            >
              <option value=''>{'\u00A0'}</option>
              <option value='4'>Extremely Experienced</option>
              <option value='3'>Very Experienced</option>
              <option value='2'>Moderately Experienced</option>
              <option value='1'>Less than Moderately Experienced</option>
            </select>
          )}
        </div>

        {/* <div className="attribute-inputs">
          <label>Primary Industry:</label>
          <select
            value={primaryIndustry}
            onChange={(e) => setPrimaryIndustry(e.target.value)}
          >
            <option value=''>{'\u00A0'}</option>
            {industries.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        <div className="attribute-inputs">
          <label>Secondary Industry:</label>
          <select
            value={secondaryIndustry}
            onChange={(e) => setSecondaryIndustry(e.target.value)}
          >
            <option value=''>{'\u00A0'}</option>
            {industries.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        <div className="attribute-inputs">
          <label>Other Industry:</label>
          <select
            value={otherIndustry}
            onChange={(e) => setOtherIndustry(e.target.value)}
          >
            <option value=''>{'\u00A0'}</option>
            {industries.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div> */}

        <div className="attribute-inputs">
          <label>Industry:</label>
          <select
            value={industry}
            onChange={(e) => setIndustry(e.target.value)}
          >
            <option value=''>{'\u00A0'}</option>
            {industries.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>

        {/* <div className="attribute-inputs">
          <label>Primary Specialty Area:</label>
          <select
            value={primarySpeciality}
            onChange={(e) => setPrimarySpecialty(e.target.value)}
          >
            <option value=''>{'\u00A0'}</option>
            {areas.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        <div className="attribute-inputs">
          <label>Secondary Specialty Area:</label>
          <select
            value={secondarySpecialty}
            onChange={(e) => setSecondarySpecialty(e.target.value)}
          >
            <option value=''>{'\u00A0'}</option>
            {areas.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        <div className="attribute-inputs">
          <label>Other Specialty Area:</label>
          <select
            value={otherSpecialty}
            onChange={(e) => setOtherSpecialty(e.target.value)}
          >
            <option value=''>{'\u00A0'}</option>
            {areas.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div> */}

        <div className="attribute-inputs">
          <label>Specialty Area:</label>
          <select
            value={specialty}
            onChange={(e) => setSpecialty(e.target.value)}
          >
            <option value=''>{'\u00A0'}</option>
            {areas.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>

        <div className="attribute-inputs">
          <label>Primary Role:</label>
          <select
            value={primaryRole}
            onChange={(e) => setPrimaryRole(e.target.value)}
          >
            <option value=''>{'\u00A0'}</option>
            {roles.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>

        <div className="attribute-inputs">
          <label>Secondary Role:</label>
          <select
            value={secondaryRole}
            onChange={(e) => setSecondaryRole(e.target.value)}
          >
            <option value=''>{'\u00A0'}</option>
            {roles.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>

        <div className="attribute-inputs">
          <label>Federal Agency:</label>
          <select
            value={agency}
            onChange={(e) => setAgency(e.target.value)}
          >
            <option value=''>{'\u00A0'}</option>
            {agencies.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>

        <div className="attribute-inputs">
          <label>Exp Federal:</label>
          <select value={yearsFederalType} onChange={(e) => setYearsFederalType(e.target.value)}>
            <option value="">Ignore</option>
            <option value="moreThan">More Than</option>
            <option value="equalTo">Equal To</option>
            <option value="lessThan">Less Than</option>
            {/* <option value="between">Between</option> */}
          </select>
          {yearsFederalType !== '' && (
            <input
            type="text"
            value={yearsFederalValue}
            onChange={(e) => setYearsFederalValue(e.target.value)}
          />
          )}
        </div>

        <div className="attribute-inputs">
          <label>Primary IRS Organization:</label>
          <select
            value={primaryIrs}
            onChange={(e) => setPrimaryIrs(e.target.value)}
          >
            <option value=''>{'\u00A0'}</option>
            {irsOrgs.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>

        <div className="attribute-inputs">
          <label>Secondary IRS Organization:</label>
          <select
            value={secondaryIrs}
            onChange={(e) => setSecondaryIrs(e.target.value)}
          >
            <option value=''>{'\u00A0'}</option>
            {irsOrgs.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>

        <div className="attribute-inputs">
            <label>Institution:</label>
            <input type="text" value={institution} onChange={(e) => setInstitution(e.target.value)} />
          </div>

        <div className="attribute-inputs">
          <label>Availability:</label>
          <select value={availablityType} onChange={(e) => setAvailabilityType(e.target.value)}>
            <option value="">Ignore</option>
            <option value="moreThan">More Than</option>
            <option value="equalTo">Equal To</option>
            <option value="lessThan">Less Than</option>
            {/* <option value="between">Between</option> */}
          </select>
          {availablityType !== '' && (
            <select
              value={availablityValue}
              onChange={(e) => setAvailabilityValue(e.target.value)}
            >
              <option value=''>{'\u00A0'}</option>
              <option value='5'>80% to 100% available</option>
              <option value='4'>60% to 80% available</option>
              <option value='3'>40% to 60% available</option>
              <option value='2'>20% to 40% available</option>
              <option value='1'>0% to 20% available</option>
            </select>
          )}
        </div>

        <div className="attribute-inputs">
          <label>Availability to Travel:</label>
          <select value={travelAvailablityType} onChange={(e) => setTravelAvailablityType(e.target.value)}>
            <option value="">Ignore</option>
            <option value="moreThan">More Than</option>
            <option value="equalTo">Equal To</option>
            <option value="lessThan">Less Than</option>
            {/* <option value="between">Between</option> */}
          </select>
          {travelAvailablityType !== '' && (
            <select
              value={travelAvailabilityValue}
              onChange={(e) => setTravelAvailabilityValue(e.target.value)}
            >
              <option value=''>{'\u00A0'}</option>
              <option value='6'>80% to 100% available</option>
              <option value='5'>60% to 80% available</option>
              <option value='4'>40% to 60% available</option>
              <option value='3'>20% to 40% available</option>
              <option value='2'>1% to 20% available</option>
              <option value='1'>0% available</option>
            </select>
          )}
        </div>


        <div className="attribute-inputs">
          <label>Labor Category:</label>
          <select value={laborCategoryType} onChange={(e) => setLaborCategoryType(e.target.value)}>
            <option value="">Ignore</option>
            <option value="moreThan">More Than</option>
            <option value="equalTo">Equal To</option>
            <option value="lessThan">Less Than</option>
          </select>
          {laborCategoryType !== '' && (
            <select
              value={laborCategoryValue}
              onChange={(e) => setLaborCategoryValue(e.target.value)}
            >
              <option value=''>{'\u00A0'}</option>
              {laborCategories.map((option, index) => (

                <option key={option} value={String(index)}>
                  {option}
                </option>
              ))}
            </select>
          )}
        </div>

        <div className="attribute-inputs">
          <label>Pay Rate:</label>
          <select value={payRateType} onChange={(e) => setPayRateType(e.target.value)}>
            <option value="">Ignore</option>
            <option value="moreThan">More Than</option>
            <option value="equalTo">Equal To</option>
            <option value="lessThan">Less Than</option>
          </select>
          {payRateType !== '' && (
            <input
              type="text"
              value={payRateValue}
              onChange={(e) => setPayRateValue(e.target.value)}
              placeholder="Value"
            />
          )}
        </div>

        <div className="attribute-inputs">
          <label>Billing Rate:</label>
          <select value={billinngRateType} onChange={(e) => setBillingRateType(e.target.value)}>
            <option value="">Ignore</option>
            <option value="moreThan">More Than</option>
            <option value="equalTo">Equal To</option>
            <option value="lessThan">Less Than</option>
          </select>
          {billinngRateType !== '' && (
            <input
              type="text"
              value={billingRateValue}
              onChange={(e) => setBillingRateValue(e.target.value)}
              placeholder="Value"
            />
          )}
        </div>

        <div className="attribute-inputs">
          <label>Starting After (yyyy-mm-dd):</label>
          <input type="text" value={start} onChange={(e) => setStart(e.target.value)} />
        </div>

        <div className="attribute-inputs">
          <label>Ending Before (yyyy-mm-dd):</label>
          <input type="text" value={end} onChange={(e) => setEnd(e.target.value)} />
        </div>


        <button className="run-query-btn" onClick={runQuery}>
          Run Query
        </button>
      </div>
      <div className="results-container">
        <div className="results-section">
          <h2>Results</h2>
          <div className="table-container">
            <table className="results-table">
              <thead>
                <tr>
                  {queryAttributes.map((attribute) => (
                    <th key={attribute.label}>{attribute.label}</th>
                  ))}
                  {results.some(([userId, userData]) => userData.resumeName) && <th>Resume</th>}
                  <th>View Profile</th>
                  <th>Inactivate</th> {/* New column header */}
                </tr>
              </thead>
              <tbody>
                {results.map(([userId, userData]) => (
                  <tr key={userId}>
                    {queryAttributes.map((attribute) => (
                      <td key={attribute.label}>
                        {renderCellValue(attribute, userData)}
                      </td>
                    ))}
                    {userData.resumeName ? (
                      <>
                        <td>
                          <button onClick={() => handleDownload(userId)} className="view-profile-button">
                            {userData.resumeName}
                          </button>
                        </td>
                        <td>
                          <button onClick={() => navigate(`/admin-profile/${userId}`, { state: { isAdmin: true } })} className="view-profile-button">
                            View Profile
              </button>
                        </td>
                      </>
                    ) : (
                        <td colSpan={2}>
                          <button onClick={() => navigate(`/admin-profile/${userId}`, { state: { isAdmin: true } })} className="view-profile-button">
                            View Profile
            </button>
                        </td>
                      )}
                    <td>
                      <button className="inactive-button" onClick={() => inactivateUser(userId)}>Inactivate</button>
                    </td> {/* New button column */}
                  </tr>
                ))}
              </tbody>
            </table>

            <button onClick={handleDownloadCSV} className="csv-btn">Download CSV</button>

          </div>
        </div>
      </div>
    </div>

  );
}

export default AdminQuery;
